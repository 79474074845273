import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { SectionHeading, Subheading as SubheadingBase } from "../../../../ui-treact/components/misc/Headings";
// import { PrimaryButton as PrimaryButtonBase } from "../../../../ui-treact/components/misc/Buttons.js";
// import { Link } from "../../../../@gatsbystorefront/.gatsby-theme-storefront-shopify/utils/general";
import Illustration from 'src/images/feature.png'
import CountUpQA from "../../../utils/CountUpQA";

// import CountUp from 'react-countup';
// import VisibilitySensor from 'react-visibility-sensor';


const Container = tw.div`relative w-full bg-white`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between items-center max-w-screen-xl mx-auto py-8`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-6`;
const ImageColumn = tw(Column)`md:w-1/2 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.text_on_left ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

// const Image = styled.div(props => [
//   `background-image: url("${props.img_src}");`,
//   tw`rounded bg-contain bg-no-repeat bg-center h-full`
// ]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-800`;

const Statistics = tw.div`flex flex-col items-center sm:block text-center md:text-left mt-4`;
const Statistic = tw.div`text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-900 tracking-wide`;
const Key = tw.div`font-medium text-primary-900`;

// const PrimaryButton = styled(Link)`${tw` mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0 px-8 py-3 font-bold rounded bg-primary-900 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`}`

// const DecoratorBlob = styled.div(props => [ 
//   tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
// ]);

export default ({data,page}) => {
  let finalData= data?.schema_json;  
  let content= {
      sub_heading: "Our Track Record",
      heading: (
          <>
              We have been doing this since {' '}
              <span tw="text-primary-500">
                <CountUpQA endValue="2010" suffix="."/>
              </span>
          </>
        ),
      description: "We’re full service which means we’ve got you covered from start to finish for all your marketing services. Dedicated to creating uniquely your services programs and building a long-lasting relationship.",
      img_src: Illustration,
      text_on_left: false,
      primary_button_url:"",
      primary_button_text:"Learn More",
      statistics: [
          {
            key: "Clients",
            value: "2282"
          },
          {
            key: "Projects",
            value: "3891"
          },
          {
            key: "Awards",
            value: "1000"
          }
        ],
      // ...finalData
} 

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <img src={content.img_src} css={content.imageCss} alt="" />
        </ImageColumn>
        <TextColumn text_on_left={content.text_on_left}>
          <TextContent>
            {content.sub_heading && <Subheading>{content.sub_heading}</Subheading>}
            <Heading>{content.heading}</Heading>
            <Description>{content.description}</Description>
            {
            content.statistics ? <Statistics>
              {content.statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>
                  <CountUpQA endValue={statistic.value} suffix="+"/>
                  </Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics> : null
            }
            {/* <PrimaryButton to={content.primary_button_url}>
              {content.primary_button_text}
            </PrimaryButton> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
