import React, { useState } from 'react'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export default function CountUpQA({endValue, suffex}) {
    const [focus, setFocus] = useState(false);
    return (
        <CountUp start={focus ? 0 : null} end={endValue} redraw={true} suffix={suffex}>
        {({ countUpRef, start }) => (
            <VisibilitySensor onChange={(isVisible) => {if (isVisible) 
                {setFocus(true);}}}>
                <span ref={countUpRef} />
            </VisibilitySensor>
        )}
        </CountUp>
    )
}
